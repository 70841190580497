/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useEffect, useState } from 'react'
import { loadScript } from 'lib/utils'
import config from 'lib/config'
import AppleIcon from 'assets/apple_black.svg'
import { trackPromise } from 'react-promise-tracker'
import AuthenticationService from 'services/AuthenticationService'
import { ApiError } from 'services/ApiService'
import { ErrorTypes } from 'lib/constants/ErrorTypes'
import { AdditionalSignUpAttributes, LoginSteps } from 'lib/tracking/attribution/signupattributes'
import styled, { css } from 'styled-components'
import { useTranslation } from 'next-i18next'
import AuthCaptchaDialog from './AuthCaptchaDialog'
import theme from 'stories/utils/theme'
import { ButtonText } from 'stories/elements/Typography/Text'
import { signUpRoute } from 'lib/constants/routes'
import { storeAuthenticationOption } from 'lib/tracking/storage'

const Container = styled.div``

const ContainerButton = styled.div<{ isDisabled?: boolean }>`
    position: relative;
    height: 50px;
    width: 300px;
    display: grid;
    padding: 4px 0 4px 5%;
    transition: all 1s ease;
    animation: showAppleButton 1s 1 ease-in-out forwards;
    @keyframes showAppleButton {
        0% {
            opacity: 0;
        }
        50% {
            opacity: 0;
        }

        100% {
            opacity: 1;
        }
    }
    @media (max-width: 750px) {
        padding: 4px 0;
        width: 100%;
        place-content: center;
    }
    .button {
        ${({ isDisabled }) =>
            isDisabled
                ? css`
                      cursor: normal;
                  `
                : css`
                      cursor: pointer;
                  `}
        background-color: ${theme.colors.white};
        display: flex;
        align-items: center;
        padding: 0 10px;
        justify-content: space-between;
        border: 1px solid rgba(0, 0, 0, 0.2);
        height: 40px;
        border-radius: ${theme.borderRadius.small};
        width: 400px !important;
        @media (max-width: 750px) {
            width: 250px !important;
        }
    }
`

interface Props {
    type: LoginSteps
    additionalSignUpAttributes: AdditionalSignUpAttributes | null
    onClick(): void
    onSuccess(isNewAccount: boolean): void
    handleNoAccount(): void
    disabled?: boolean
}

export const loadingAreas = {
    container: 'authApple',
}

interface Data {
    idToken: string
    name: string | null
    hCaptchaToken: string | null
}

const loadAuthScripts = (onLoad: () => void) => {
    loadScript('https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js', () => {
        AppleID.auth.init({
            clientId: config.getAppleClientId(),
            scope: 'name email',
            redirectURI: `${window.location.origin.toString()}${signUpRoute()}`,
            usePopup: true,
        })
        onLoad()
    })
}

const AuthApple = (props: Props) => {
    const [isScriptLoaded, setIsScriptLoaded] = useState(false)
    const { t: tCommon } = useTranslation('common')
    const [showCaptchaDialog, setShowCaptchaDialog] = useState(false)
    const [data, setData] = useState<Data | null>()

    useEffect(() => {
        loadAuthScripts(() => {
            setIsScriptLoaded(true)
        })
    }, [])

    const handleClick = async () => {
        props.onClick()
        let name: string | null
        let idToken: string

        try {
            const response = await AppleID.auth.signIn()
            name = response.user ? `${response.user.name.firstName} ${response.user.name.lastName}` : null
            idToken = response.authorization.id_token

            // eslint-disable-next-line
        } catch (error: any) {
            if (
                error.error === 'popup_closed_by_user' ||
                error.error === 'user_trigger_new_signin_flow' ||
                error.error === 'user_cancelled_authorize'
            ) {
                return
            }

            throw error
        }

        storeAuthenticationOption('Apple ID')

        const data: Data = {
            idToken,
            name,
            hCaptchaToken: null,
        }

        setData(data)
        handle(data)
    }

    const handleCaptchaVerify = (hCaptchaToken: string) => {
        if (!data) return
        setShowCaptchaDialog(false)
        const updatedData = {
            ...data,
            hCaptchaToken,
        }
        setData(updatedData)
        handle(updatedData)
    }

    const handle = (data: Data) => {
        if (props.type === LoginSteps.SignIn) {
            signIn(data.idToken, data.hCaptchaToken)
        } else {
            signUp(data.idToken, data.name, data.hCaptchaToken)
        }
    }

    const signIn = async (idToken: string, hCaptchaToken: string | null) => {
        try {
            await trackPromise(AuthenticationService.signInApple(idToken, hCaptchaToken), loadingAreas.container)
        } catch (error) {
            if (error instanceof ApiError) {
                if (error.type === ErrorTypes.InvalidCredentials) {
                    return props.handleNoAccount()
                } else if (error.type === ErrorTypes.RequiresCaptcha) {
                    setShowCaptchaDialog(true)
                } else {
                    error.handleUnknownError(tCommon, 'auth.signInApple')
                }
                return
            } else {
                throw error
            }
        }

        props.onSuccess(false)
    }

    const signUp = async (idToken: string, name: string | null, hCaptchaToken: string | null) => {
        if (!props.additionalSignUpAttributes) return

        try {
            await trackPromise(
                AuthenticationService.signUpApple(idToken, name, props.additionalSignUpAttributes, hCaptchaToken),
                loadingAreas.container
            )
        } catch (error) {
            if (error instanceof ApiError) {
                if (error.type === ErrorTypes.Duplicate) {
                    console.error('The user already has an account.')
                    return signIn(idToken, hCaptchaToken)
                } else if (error.type === ErrorTypes.RequiresCaptcha) {
                    setShowCaptchaDialog(true)
                } else {
                    error.handleUnknownError(tCommon, 'auth.signUpApple')
                }
                return
            } else {
                throw error
            }
        }

        props.onSuccess(true)
    }

    if (!isScriptLoaded) return null

    return (
        <Container>
            <AuthCaptchaDialog show={showCaptchaDialog} onVerify={handleCaptchaVerify} />
            <ContainerButton isDisabled={!!props.disabled} onClick={props.disabled ? () => {} : handleClick}>
                <div className="button">
                    <ButtonText color={theme.colors.body1Black} fontFamily="var(--font-inter)" fontWeight={400}>
                        {props.type === LoginSteps.SignIn
                            ? tCommon('common/signInWithAppleButton')
                            : tCommon('common/signUpWithAppleButton')}
                    </ButtonText>
                    <AppleIcon />
                </div>
            </ContainerButton>
        </Container>
    )
}

export default AuthApple
