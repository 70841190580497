import { CountryCode } from 'interfaces/Country'
import { Language } from 'interfaces/Language'
import attribution from 'lib/tracking/attribution/attribution'

export enum LoginSteps {
    SignUp = 'signUp',
    SignUpEmail = 'signUpEmail',
    SignIn = 'signIn',
    SignInEmail = 'signInEmail',
}

export interface AdditionalSignUpAttributes {
    referredByUsername: string | null
    source: string | null
    sourceCampaign: string | null
    countryCode: string
    regionId: number | null
    gradeId: number | null
    schoolTypeId: number | null
    interfaceLanguageCode: Language
    issueSubjectsIds: number[]
    schoolId: number | null
    subscribedToNewsletter: boolean
    seoConvertedKnowUuid: string | null
}

export function getDefaultAdditionalSignUpAttributes(
    countryCode: CountryCode,
    interfaceLanguageCode: Language,
    knowUuid?: string
): AdditionalSignUpAttributes {
    const attributionData = attribution.getAttribution()

    return {
        referredByUsername: null,
        source: attributionData.source ?? null,
        sourceCampaign: attributionData.sourceCampaign ?? null,
        countryCode,
        regionId: null,
        gradeId: null,
        schoolTypeId: null,
        interfaceLanguageCode,
        issueSubjectsIds: [],
        schoolId: null,
        subscribedToNewsletter: true,
        seoConvertedKnowUuid: knowUuid ?? null,
    }
}

export function getAdditionalSignUpAttributesForExpansion(
    subscribedToNewsletter: boolean,
    countryCode: CountryCode,
    interfaceLanguageCode: Language,
    regionId: number | null
): AdditionalSignUpAttributes {
    const attributionData = attribution.getAttribution()

    return {
        referredByUsername: null,
        source: attributionData.source ?? null,
        sourceCampaign: attributionData.sourceCampaign ?? null,
        countryCode,
        regionId,
        gradeId: null,
        schoolTypeId: null,
        interfaceLanguageCode,
        issueSubjectsIds: [],
        schoolId: null,
        subscribedToNewsletter: subscribedToNewsletter,
        seoConvertedKnowUuid: null,
    }
}
