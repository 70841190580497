import React, { FunctionComponent, useEffect, useRef } from 'react'
import { useField } from '@unform/core'
import InfoIcon from 'assets/icons/info.svg'
import { Subtitle1 } from 'stories/elements/Typography/Text'
import { InputPropTypes, InputProps } from './InputTypes'
import { FormGroup, Tooltip, TooltipText, ErrorMessage } from './Input.css'

const Input: FunctionComponent<InputProps & JSX.IntrinsicElements['input']> = (props) => {
    const { name, parentError, ...rest } = props
    const inputRef = useRef(null)
    const { fieldName, defaultValue, registerField, error } = useField(name)
    const errorText = error ?? parentError
    const isError = !!errorText

    useEffect(() => {
        registerField({
            name: fieldName,
            ref: inputRef.current,
            path: 'value',
        })
        if (props.setRef) {
            props.setRef(inputRef)
        }
    }, [props, fieldName, registerField])

    const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        props.onUpdate && props.onUpdate(e.target.value)
    }

    return (
        <FormGroup
            noMargin={!!props.noMargin}
            height={props.height}
            isError={isError}
            className="input"
            darkTheme={props.darkTheme}
        >
            {props?.hideLabel ? null : (
                <label htmlFor={name}>
                    <Subtitle1>{props.label ?? props.placeholder}</Subtitle1>
                    {props.tooltip ? (
                        <Tooltip>
                            <InfoIcon />
                            <TooltipText>{props.tooltip}</TooltipText>
                        </Tooltip>
                    ) : null}
                </label>
            )}
            <input ref={inputRef} defaultValue={defaultValue} {...rest} onInput={handleInput} id={name} />
            <ErrorMessage>{errorText}</ErrorMessage>
        </FormGroup>
    )
}

Input.propTypes = InputPropTypes

export default Input
