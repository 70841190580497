import { useCallback, useEffect, useRef } from 'react'
import { loadScript } from 'lib/utils'
import config from 'lib/config'

interface GoogleAuthHook {
    initializeGoogleButton: (
        buttonRef: HTMLDivElement,
        options: {
            width: number
            type: 'signin_with' | 'signup_with'
            callback: (response: CredentialResponse) => void
        }
    ) => void
}

const scriptState = {
    isLoaded: false,
    loadPromise: null as Promise<void> | null,
    initialized: new Set<HTMLDivElement>(),
}

const loadGoogleScript = async () => {
    if (scriptState.isLoaded && window.google?.accounts?.id) {
        return Promise.resolve()
    }

    if (scriptState.loadPromise) {
        return scriptState.loadPromise
    }

    scriptState.loadPromise = new Promise<void>((resolve) => {
        loadScript('https://accounts.google.com/gsi/client', () => {
            if (!window.google?.accounts?.id) return
            scriptState.isLoaded = true
            resolve()
        })
    })

    return scriptState.loadPromise
}

export const useGoogleAuth = (): GoogleAuthHook => {
    const buttonRef = useRef<HTMLDivElement | null>(null)

    useEffect(() => {
        return () => {
            if (buttonRef.current) {
                scriptState.initialized.delete(buttonRef.current)
            }
        }
    }, [])

    const initializeGoogleButton = useCallback(
        async (
            ref: HTMLDivElement,
            options: {
                width: number
                type: 'signin_with' | 'signup_with'
                callback: (response: CredentialResponse) => void
            }
        ) => {
            buttonRef.current = ref

            if (scriptState.initialized.has(ref)) {
                return
            }

            try {
                await loadGoogleScript()

                if (!window.google?.accounts?.id) return

                window.google.accounts.id.initialize({
                    client_id: config.getGoogleClientId(),
                    callback: options.callback,
                    use_fedcm_for_prompt: true,
                })

                window.google.accounts.id.renderButton(ref, {
                    width: options.width,
                    shape: 'rectangular',
                    text: options.type,
                    type: 'standard',
                    size: 'large',
                    'data-use-fedcm': 'true',
                    'data-auto': 'true',
                })

                scriptState.initialized.add(ref)
            } catch (error) {
                console.error('Failed to initialize Google button:', error)
            }
        },
        []
    )

    return {
        initializeGoogleButton,
    }
}
