import theme from 'stories/utils/theme'
import styled from 'styled-components'

export const TextSeparator = styled.div<{ noMargin?: boolean }>`
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    margin: ${({ noMargin }) => (noMargin ? 0 : `20px 0`)};
    span {
        height: 0;
        border: 1px solid #c2c7cc;
        margin-top: 19px;
    }
    p {
        margin-top: 0px;
        padding: 10px;
        font-family: var(--font-open-sans);
        font-size: 13px;
        font-style: normal;
        font-weight: 600;
        line-height: 18px;
        letter-spacing: 0em;
        text-align: center;
        color: ${theme.colors.body2Black};
    }
`
