import React, { useCallback, useRef, useState, useEffect, useMemo, memo } from 'react'
import { trackPromise } from 'react-promise-tracker'
import AuthenticationService from 'services/AuthenticationService'
import { ApiError } from 'services/ApiService'
import { ErrorTypes } from 'lib/constants/ErrorTypes'
import { AdditionalSignUpAttributes, LoginSteps } from 'lib/tracking/attribution/signupattributes'
import styled from 'styled-components'
import { useTranslation } from 'next-i18next'
import AuthCaptchaDialog from './AuthCaptchaDialog'
import useMedia from 'lib/hooks/useMedia'
import { deviceSize } from 'lib/constants/GridBreakpoints'
import { storeAuthenticationOption } from 'lib/tracking/storage'
import { useGoogleAuth } from 'lib/hooks/useGoogleAuth'

const Container = styled.div<{ isSmall?: boolean }>`
    margin: ${({ isSmall }) => (isSmall ? '0px' : '10px 0')};
`

const ContainerButton = styled.div<{ isSmall?: boolean; disableExtraStyling?: boolean }>`
    position: relative;
    height: 50px;
    width: ${({ isSmall }) => (isSmall ? 150 : 300)}px;
    display: grid;
    padding: ${({ disableExtraStyling }) => (!disableExtraStyling ? '4px 0 4px 5%' : '')};
    @media (max-width: 750px) {
        width: 100%;
        place-content: start;
        padding: 0px 0 0px 25px;
    }
    && div {
        border: none;
        width: ${({ isSmall }) => (isSmall ? 150 : 375)}px !imporant;
        margin: 0;
        @media (max-width: 750px) {
            width: 225px !important;
        }
    }
`

export const loadingAreas = {
    container: 'authGoogle',
}

interface Data {
    idToken: string
    hCaptchaToken: string | null
}

const GoogleWrapper = styled.div`
    transition: all 1.5s ease;
    animation: showGoogleButton 1.5s 1 ease-in-out forwards;
    @keyframes showGoogleButton {
        0% {
            opacity: 0;
        }
        50% {
            opacity: 0;
        }

        100% {
            opacity: 1;
        }
    }
`

interface Props {
    type: LoginSteps
    additionalSignUpAttributes: AdditionalSignUpAttributes | null
    onClick(): void
    onSuccess(isNewAccount: boolean): void
    handleNoAccount(): void
    disabled?: boolean
    isSmall?: boolean
    isInline?: boolean
    disableExtraStyling?: boolean
}

const AuthGoogle = memo((props: Props) => {
    const { t: tCommon } = useTranslation('common')
    const isMobile = useMedia(deviceSize.md)
    const { initializeGoogleButton } = useGoogleAuth()
    const divRef = useRef<HTMLDivElement>(null)
    const captchaDialogRef = useRef<{ show: boolean; token: string | null }>({
        show: false,
        token: null,
    })

    const signIn = useCallback(
        async (idToken: string, hCaptchaToken: string | null) => {
            try {
                await trackPromise(AuthenticationService.signInGoogle(idToken, hCaptchaToken))
                props.onSuccess(false)
            } catch (error) {
                if (error instanceof ApiError) {
                    if (error.type === ErrorTypes.InvalidCredentials) {
                        props.handleNoAccount()
                    } else if (error.type === ErrorTypes.RequiresCaptcha) {
                        captchaDialogRef.current.show = true
                        captchaDialogRef.current.token = idToken
                    } else {
                        error.handleUnknownError(tCommon, 'auth.signInGoogle')
                    }
                    return
                }
                throw error
            }
        },
        [props, tCommon]
    )

    const signUp = useCallback(
        async (idToken: string, hCaptchaToken: string | null) => {
            if (!props.additionalSignUpAttributes) return

            try {
                await trackPromise(
                    AuthenticationService.signUpGoogle(idToken, props.additionalSignUpAttributes, hCaptchaToken)
                )
                props.onSuccess(true)
            } catch (error) {
                if (error instanceof ApiError) {
                    if (error.type === ErrorTypes.Duplicate) {
                        return signIn(idToken, hCaptchaToken)
                    } else if (error.type === ErrorTypes.RequiresCaptcha) {
                        captchaDialogRef.current.show = true
                        captchaDialogRef.current.token = idToken
                    } else {
                        error.handleUnknownError(tCommon, 'auth.signUpGoogle')
                    }
                    return
                }
                throw error
            }
        },
        [props, signIn, tCommon]
    )

    const handleClick = useCallback(
        async ({ credential: idToken }: CredentialResponse) => {
            if (!idToken) return
            props.onClick()

            storeAuthenticationOption('Google')

            if (props.type === LoginSteps.SignIn) {
                signIn(idToken, null)
            } else {
                signUp(idToken, null)
            }
        },
        [props, signIn, signUp]
    )

    const handleCaptchaVerify = useCallback(
        (hCaptchaToken: string) => {
            const { token } = captchaDialogRef.current
            if (!token) return

            captchaDialogRef.current.show = false
            captchaDialogRef.current.token = null

            if (props.type === LoginSteps.SignIn) {
                signIn(token, hCaptchaToken)
            } else {
                signUp(token, hCaptchaToken)
            }
        },
        [props.type, signIn, signUp]
    )

    const buttonConfig = useMemo(() => {
        const type = props.type === LoginSteps.SignIn ? 'signin_with' : 'signup_with'
        return {
            width: props.isInline ? 315 : props.isSmall ? 150 : isMobile ? 250 : 400,
            type: type as 'signin_with' | 'signup_with',
            callback: handleClick,
        }
    }, [props.isInline, props.isSmall, isMobile, props.type, handleClick])

    useEffect(() => {
        if (!divRef.current) return
        initializeGoogleButton(divRef.current, buttonConfig)
    }, [initializeGoogleButton, buttonConfig])

    return (
        <Container isSmall={props.isSmall}>
            <AuthCaptchaDialog show={captchaDialogRef.current.show} onVerify={handleCaptchaVerify} />
            <ContainerButton
                disableExtraStyling={props.disableExtraStyling}
                isSmall={props.isSmall}
                className="google-container"
            >
                <GoogleWrapper ref={divRef} className="google-icon" />
            </ContainerButton>
        </Container>
    )
})

AuthGoogle.displayName = 'AuthGoogle'

export default AuthGoogle
