import { FormHandles } from '@unform/core'
import { TFunction } from 'i18next'
import { toast } from 'react-toastify'
import { ApiError } from 'services/ApiService'

export function handleFormValidationErrors(t: TFunction<'common'>, error: ApiError, formRef?: FormHandles) {
    if (!formRef) return

    const fields = error.data as {
        [fieldName: string]: {
            type: string
            min: number | null
            max: number | null
        }
    }

    for (const fieldName in fields) {
        if (!Object.prototype.hasOwnProperty.call(formRef.getData(), fieldName)) {
            toast.error(`${t('common/formValidationUnknownFieldError')} (${fieldName}).`)
            const errorMessage = `There is an unknown field '${fieldName}' in form validation error`
            console.error(errorMessage)
        }

        const field = fields[fieldName]
        switch (field.type) {
            case 'required':
                formRef.setFieldError(fieldName, t('common/formValidationRequiredError'))
                break
            case 'size':
                if (field.min && field.min === 1) {
                    formRef.setFieldError(fieldName, t('common/formValidationSizeMin1Error'))
                } else if (field.min) {
                    formRef.setFieldError(
                        fieldName,
                        t('common/formValidationSizeMinError', {
                            min: field.min,
                        })
                    )
                } else {
                    formRef.setFieldError(
                        fieldName,
                        t('common/formValidationSizeMaxError', {
                            max: field.max,
                        })
                    )
                }
                break
            case 'length':
                if (field.min) {
                    formRef.setFieldError(
                        fieldName,
                        t('common/formValidationLengthMinError', {
                            min: field.min,
                        })
                    )
                } else {
                    formRef.setFieldError(
                        fieldName,
                        t('common/formValidationLengthMaxError', {
                            max: field.max,
                        })
                    )
                }
                break
            case 'email':
                formRef.setFieldError(fieldName, t('common/formValidationEmailError'))
                break
            case 'e164':
                formRef.setFieldError(fieldName, t('common/formValidationE164Error'))
                break
            default: {
                formRef.setFieldError(fieldName, t('common/formValidationUnknownError'))
                const errorMessage = `There is an unknown form validation error type '${field.type}'`
                console.error(errorMessage)
            }
        }
    }
}

export function validateFormLocally<T extends object>(data: T, form: React.RefObject<FormHandles>, t: TFunction<'common'>) {
    let error = false
    Object.entries(data).forEach(([fieldName, fieldValue]) => {
        if (!fieldValue) {
            error = true
            form.current!.setFieldError(fieldName, t('common/formValidationRequiredError'))
        }
    })

    return error
}

export function validateObjectLocally<T extends object>(data: T) {
    let error = false
    Object.values(data).forEach((fieldValue) => {
        if (!fieldValue) {
            error = true
        }
    })

    return error
}
