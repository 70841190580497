import { GlobalAuthService } from './ApiService'
import { AuthToken } from 'interfaces/AuthToken'
import { setOauthToken } from '../redux/actions'
import store from '../redux/store'
import UserService from './UserService'
import { AdditionalSignUpAttributes } from 'lib/tracking/attribution/signupattributes'

const HCaptchaTokenHeader = 'X-HCaptcha'

const handleAuthenticated = (data: AuthToken) => {
    store().dispatch(setOauthToken(data))
    return UserService.fetchUserAndKnower().then((user) => {
        return data
    })
}

const getAuthHeaders = (hCaptchaToken: string | null) => {
    const headers: Record<string, string> = {}
    if (hCaptchaToken) {
        headers[HCaptchaTokenHeader] = hCaptchaToken
    }
    return headers
}

const AuthenticationService = {
    signUpEmail(
        name: string,
        email: string,
        password: string,
        additionalSignUpAttributes: AdditionalSignUpAttributes,
        hCaptchaToken: string | null
    ) {
        return GlobalAuthService.post(
            'auth/signup/email',
            {
                name,
                email,
                password,
                ...additionalSignUpAttributes,
            },
            {
                headers: getAuthHeaders(hCaptchaToken),
            }
        ).then((response) => {
            const data = response.data as AuthToken
            return handleAuthenticated(data)
        })
    },

    signInEmail(email: string, password: string, twoFactorAuthenticationToken: string | null, hCaptchaToken: string | null) {
        return GlobalAuthService.post(
            'auth/signin/email',
            {
                email,
                password,
                twoFactorAuthenticationToken,
            },
            {
                headers: getAuthHeaders(hCaptchaToken),
            }
        ).then((response) => {
            const data = response.data as AuthToken
            return handleAuthenticated(data)
        })
    },

    signUpGoogle(idToken: string, additionalSignUpAttributes: AdditionalSignUpAttributes, hCaptchaToken: string | null) {
        return GlobalAuthService.post(
            'auth/signup/google',
            {
                idToken,
                ...additionalSignUpAttributes,
            },
            {
                headers: getAuthHeaders(hCaptchaToken),
            }
        ).then((response) => {
            const data = response.data as AuthToken
            return handleAuthenticated(data)
        })
    },

    signInGoogle(idToken: string, hCaptchaToken: string | null) {
        return GlobalAuthService.post(
            'auth/signin/google',
            { idToken },
            {
                headers: getAuthHeaders(hCaptchaToken),
            }
        ).then((response) => {
            const data = response.data as AuthToken
            return handleAuthenticated(data)
        })
    },

    signInApple(idToken: string, hCaptchaToken: string | null) {
        return GlobalAuthService.post(
            'auth/signin/apple',
            { idToken },
            {
                headers: getAuthHeaders(hCaptchaToken),
            }
        ).then((response) => {
            const data = response.data as AuthToken
            return handleAuthenticated(data)
        })
    },

    signUpApple(
        idToken: string,
        name: string | null,
        additionalSignUpAttributes: AdditionalSignUpAttributes,
        hCaptchaToken: string | null
    ) {
        return GlobalAuthService.post(
            'auth/signup/apple',
            {
                idToken,
                name,
                ...additionalSignUpAttributes,
            },
            {
                headers: getAuthHeaders(hCaptchaToken),
            }
        ).then((response) => {
            const data = response.data as AuthToken
            return handleAuthenticated(data)
        })
    },

    forgotPassword(email: string, hCaptchaToken: string) {
        return GlobalAuthService.post(
            '/auth/password-reset',
            { email },
            {
                headers: getAuthHeaders(hCaptchaToken),
            }
        )
    },

    resetPassword(token: string, password: string, hCaptchaToken: string) {
        return GlobalAuthService.post(
            '/auth/password-reset/confirm',
            {
                token,
                password,
            },
            {
                headers: getAuthHeaders(hCaptchaToken),
            }
        )
    },

    delete(accountUuid: string) {
        return GlobalAuthService.delete(`/accounts/${accountUuid}`)
    },
}

export default AuthenticationService
