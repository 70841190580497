/* eslint-disable @typescript-eslint/no-empty-function */
import React from 'react'
import HCaptcha from '@hcaptcha/react-hcaptcha'
import config from 'lib/config'
import { useTranslation } from 'next-i18next'
import FeatureDialog from 'stories/elements/Modal/DialogWindow/FeatureDialog'
import styled from 'styled-components'
import { Body1 } from 'stories/elements/Typography/Text'
import theme from 'stories/utils/theme'

interface Props {
    show: boolean
    onVerify(token: string): void
}

const Section = styled.div`
    display: grid;
    place-items: center;
    gap: 30px;
`

const AuthCaptchaDialog = (props: Props) => {
    const { t } = useTranslation('auth')

    return (
        <FeatureDialog
            open={props.show}
            title={t('auth/captchaDialogTitle')}
            disableBackdropClick
            hideCloseButton
            ariaPrefix={'auth-captcha-dialog'}
            onClose={() => {}}
        >
            <Section>
                <Body1 color={theme.colors.body2Black}>{t('auth/captchaDialogText')}</Body1>
                <HCaptcha sitekey={config.getHCaptchaKey()} onVerify={props.onVerify} />
            </Section>
        </FeatureDialog>
    )
}

export default AuthCaptchaDialog
